<template>
  <div v-if="items != null" class="w-100" ref="container">
    <div class="p-2" v-if="blnSearch == true">
      <Search
        :mode="'auto'"
        :hint="$t('447', { fieldname: $t(hint).toLowerCase() })"
        @onSearch="searchDropdown($event)"
        @onClean="clear()"
        ref="Search"
      />
    </div>
    <div class="list-item" id="scroll" ref="listitem" @scroll="handleScroll">
      <template v-for="(item, index) in listDropdownCrop" :key="index">
        <div
          class="item text-black"
          @click="selected(item)"
          :class="device == 'mobile' ? 'fnt-caption' : ''"
          v-if="enable == false && enablelist == false"
          id="itemsList"
        >
          <!-- {{ item }} -->

          {{ blnSystemword == true ? $t(item.lang) : item[filterLang(item)] }}
        </div>
        <div
          id="itemsList"
          class="item text-black"
          @click="selected(item)"
          :class="device == 'mobile' ? 'fnt-caption' : ''"
          v-if="enable == true && enablelist == false && item.intstatus == true"
        >
          {{ blnSystemword == true ? $t(item.lang) : item[filterLang(item)] }}
        </div>
        <div
          id="itemsList"
          class="item text-black"
          @click="selected(item)"
          :class="device == 'mobile' ? 'fnt-caption' : ''"
          v-if="
            enablelist == true && enable == false && item.closestatus == true
          "
        >
          {{ blnSystemword == true ? $t(item.lang) : item[filterLang(item)] }}
        </div>
        <div
          id="itemsList"
          class="item text-black"
          @click="selected(item)"
          :class="device == 'mobile' ? 'fnt-caption' : ''"
          v-if="enablelist == true && enable == true && item.inprocess == true"
        >
          {{ blnSystemword == true ? $t(item.lang) : item[filterLang(item)] }}
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import Search from "@/components/input/inputSearch.vue";
import customfilter from "@/typescript/filter";
export default {
  data() {
    return {
      strResult: "",
      listDropdown: [],
      maxcount: 20,
      mincount: 0,
    };
  },
  emits: ["onChange"],
  watch: {
    items: {
      deep: true,
      handler(newValue) {
        // console.log(newValue);
        this.listDropdown = newValue;
        this.resetData();
      },
    },
    selectedValue: {
      deep: true,
      handler() {
        // console.log(newValue);
        this.resetData();
      },
    },
  },
  methods: {
    checkScroll() {
      // console.log();
    },
    resetData() {
      // console.log("resetData");
      if (this.selectedValue == "") {
        return;
      }
      if (
        this.blnselected == true ||
        (this.selectedValue !== "" &&
          this.items !== undefined &&
          this.blnAddress == false)
      ) {
        Object.values(this.items).filter((e) => {
          // console.log(this.blnSystemword == true ? e.id : e.strID);
          // console.log(this.selectedValue);
          // console.log(e.strID == this.selectedValue);

          // if (
          //   (this.blnSystemword == true
          //     ? e.id
          //     : e.strID[0] == undefined
          //     ? e.strID
          //     : e.strID[0]) == this.selectedValue
          // ) {
          if (this.blnSystemword == true && e.id == this.selectedValue) {
            this.strResult = e.lang;
            // console.log(e);
            this.selected(e);
          } else if (
            this.blnSystemword == false &&
            e.strID == this.selectedValue
          ) {
            // console.log(this.selectedValue, e);
            // console.log("Call function selected");
            this.selected(e);
          }
          if (e.strID != undefined) {
            if (e.strID.length > 1) {
              for (let i = 0; i < e.strID.length; i++) {
                if (
                  this.blnSystemword == false &&
                  e.strID[i] == this.selectedValue
                ) {
                  // console.log(this.selectedValue, e);
                  // console.log("Call function selected");
                  this.selected(e);
                }
              }
            } else {
              if (e.strID[0] != undefined) {
                if (
                  this.blnSystemword == false &&
                  e.strID[0] == this.selectedValue
                ) {
                  this.selected(e);
                }
              }
            }
          } else {
            if (e.id.length > 1) {
              for (let i = 0; i < e.id.length; i++) {
                if (
                  this.blnSystemword == false &&
                  e.id[i] == this.selectedValue
                ) {
                  // console.log(this.selectedValue, e);
                  // console.log("Call function selected");
                  this.selected(e);
                }
              }
            }
          }
        });
      } else if (this.blnAddress == true && this.selectedLookup !== "") {
        Object.values(this.items).filter((e) => {
          if (e.strID == undefined && this.selectedLookup != undefined) {
            if (e.nameEN != undefined && e.nameTH != undefined) {
              if (
                e.nameEN == this.selectedLookup.nameEN &&
                e.nameTH == this.selectedLookup.nameTH
              ) {
                this.selected(e);
              }
            } else if (e.strNameEN != undefined && e.strNameTH != undefined) {
              if (
                e.strNameEN == this.selectedLookup.strNameEN &&
                e.strNameTH == this.selectedLookup.strNameTH
              ) {
                // console.log(e);
                this.selected(e);
              }
            }
          } else if (e.intSubdistrictID != undefined) {
            if (e.intSubdistrictID == this.selectedValue) {
              if (this.blnSystemword == true) {
                this.strResult = e.strNameTH;
                // console.log("Call function selected2.1");
                this.selected(e);
              } else if (this.blnSystemword == false) {
                // console.log("Call function selected2.2");
                this.selected(e);
              }
            }
          } else if (e.intSubdistrictID == undefined) {
            if (e.strID == this.selectedValue) {
              if (this.blnSystemword == true) {
                this.strResult = e.strNameTH;
                // console.log("Call function selected3.1");
                this.selected(e);
              } else if (this.blnSystemword == false) {
                // console.log("Call function selected3.2");
                this.selected(e);
              }
            }
          }
        });
      } else if (this.blnAddress == true && this.selectedValue == "") {
        Object.values(this.items).filter((e) => {
          // No ID
          if (e.strID == undefined) {
            if (e.nameEN != undefined && e.nameTH != undefined) {
              if (
                e.nameEN == this.selectedLookup.nameEN &&
                e.nameTH == this.selectedLookup.nameTH
              ) {
                // console.log(e);
                this.selected(e);
              }
            } else if (e.strNameEN != undefined && e.strNameTH != undefined) {
              if (
                e.strNameEN == this.selectedLookup.strNameEN &&
                e.strNameTH == this.selectedLookup.strNameTH
              ) {
                // console.log(e);
                this.selected(e);
              }
            }
          }
        });
      }
    },
    clear() {
      if (this.$refs.Search != undefined) {
        this.$refs.Search.cleanfromlist();
      }

      this.listDropdown = { ...this.items };
    },
    selected(e) {
      // console.log(e);
      this.strResult =
        this.blnSystemword == true ? e.lang : e[this.filterLang(e)];
      // console.log(e);
      const obj = {
        id:
          this.blnSystemword == true
            ? e.id
            : this.blnAddress == false
            ? e.strID === undefined
              ? e.id
              : e.strID
            : e.intProvinceID == undefined ||
              e.intDistrictID == undefined ||
              e.intSubdistrictID == undefined ||
              e.strPostcode == undefined
            ? e.strID === undefined
              ? e.id ?? null
              : e.strID
            : {
                intProvinceID: e.intProvinceID,
                intDistrictID: e.intDistrictID,
                intSubdistrictID: e.intSubdistrictID,
                strPostcode: e.strPostcode,
              },
        lang:
          this.blnAddress == false
            ? this.blnSystemword == true
              ? e.lang
              : e[this.filterLang(e)]
            : {
                langEN: e.strNameEN == undefined ? e.nameEN : e.strNameEN,
                langTH: e.strNameTH == undefined ? e.nameTH : e.strNameTH,
              },
      };
      // console.log(obj);

      this.$emit("onChange", obj);
    },
    filterLang(items) {
      // console.log(this.defaultLang);
      return customfilter.filterLang(items, this.$i18n?.locale);
    },

    searchDropdown(key) {
      if (key != null) {
        this.listDropdown = this.filterSelected({ ...this.items }, key);
      } else {
        this.listDropdown = { ...this.items };
      }
    },
    filterSelected(array, event) {
      // console.log(array);
      // console.log(event);
      // const keysExact = ["NameEN", "NameTH"];
      return Object.values(array).filter((item) => {
        if (this.blnSystemword == false) {
          if (this.$i18n.locale === "EN" || this.$i18n.locale === "en") {
            if (item["strNameEN"] != undefined) {
              return (
                item["strNameEN"]
                  .toString()
                  .toLowerCase()
                  .indexOf(event.toString().toLowerCase()) !== -1
              );
            } else {
              return (
                item["nameEN"]
                  .toString()
                  .toLowerCase()
                  .indexOf(event.toString().toLowerCase()) !== -1
              );
            }
          } else {
            if (item["strNameTH"] != undefined) {
              return (
                item["strNameTH"]
                  .toString()
                  .toLowerCase()
                  .indexOf(event.toString().toLowerCase()) !== -1
              );
            } else {
              return (
                item["nameTH"]
                  .toString()
                  .toLowerCase()
                  .indexOf(event.toString().toLowerCase()) !== -1
              );
            }
          }
        } else if (this.blnSystemword == true) {
          // console.log(this.$t(item["lang"]));
          return (
            this.$t(item["lang"])
              .toString()
              .toLowerCase()
              .indexOf(event.toString().toLowerCase()) !== -1
          );
        }
      });
    },
    handleScroll(e) {
      // console.log(e.target.scrollHeight);
      // console.log(scroll.scrollTop + 44 * 7);
      if (
        e.target.scrollTop + 44 * 7 >= e.target.scrollHeight &&
        this.maxcount >= 20
      ) {
        this.maxcount = this.maxcount + 20;
        // this.mincount = this.mincount + 20;
        // console.log(this.maxcount);
      }
    },
  },
  components: {
    Search,
  },
  props: {
    items: { type: Object },
    blnSystemword: { type: Boolean, default: true },
    selectedValue: { type: String },
    blnselected: { type: Boolean, default: false },
    defaultLang: { type: String, default: null },
    blnAddress: { type: Boolean, default: false },
    enable: { type: Boolean, default: false },
    enablelist: { type: Boolean, default: false },
    hint: { type: String },
    blnSearch: { type: Boolean, default: true },
    selectedLookup: { type: Object },
  },
  computed: {
    listDropdownCrop() {
      return Object.values(this.listDropdown).slice(
        this.mincount,
        this.maxcount
      );
    },
  },
  mounted() {
    // console.log(this.selectedValue);

    this.listDropdown = { ...this.items };
    // console.log(this.items);
    this.locales = localStorage.getItem("language");
    //ถ้าไม่เจอค่า
    if (this.locales == null || this.locales == "") {
      //ตั้งค่าเริ่มต้นเป็นภาษาอังกฤษ
      this.locales = "en";
    }
    setTimeout(() => {
      this.resetData();
    }, 500);
    if (this.listDropdown.length < 20) {
      this.maxcount = this.listDropdown.length;
    }
    // setTimeout(() => {
    //   this.items.forEach((element) => {
    //     // console.log(element);
    //     if (element.strID == this.selectedValue) {
    //       console.log(element);
    //       this.selected(element);
    //     }
    //   });
    // }, 1000);

    // setTimeout(() => {

    // }, 2000);
  },
  beforeCreate() {},
};
</script>

<style lang="scss" scoped>
.list-item {
  max-height: calc(44px * 7);
  min-height: fit-content;
  overflow-y: scroll;
  .item {
    max-height: max-content;
    min-height: 44px;
    background-color: $white;
    padding: 12px 16px;
    cursor: pointer;
    transition: 150ms;
    &:hover {
      background-color: $black-50;
    }
    &:active {
      background-color: $black-300;
    }
  }
}
</style>
